/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import styled from "@emotion/styled";
import { Parser } from "html-to-react";
import { uniqueId } from "lodash";
import Button from "../components/Button";
import Layout from "../components/Layout";
import ContentBlock from "../components/ContentBlock";
import bp from "../utils/media";
import ContentTeaser from "../components/ContentTeaser";

const htmlToReactParser = new Parser();

const styling = css``;

const ContentTeaserContainer = ({ headline, items }) => (
  <section>
    <ContentBlock>
      <Layout.ContentContainer>
        {htmlToReactParser.parse(headline.html)}
        <ContentTeaser.Container>
          {items.map(({ image, text }) => (
            <ContentTeaser>
              <img alt="" src={image.url} />
              <ContentTeaser.Text>
                {htmlToReactParser.parse(text.html)}
              </ContentTeaser.Text>
            </ContentTeaser>
          ))}
        </ContentTeaser.Container>
      </Layout.ContentContainer>
    </ContentBlock>
  </section>
);

export const sliceId = "contentteaser";
export default ContentTeaserContainer;
