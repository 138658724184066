/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { Parser } from "html-to-react";
import { uniqueId } from "lodash";
import IconTeaser from "../components/IconTeaser";
import Layout from "../components/Layout";
import ContentBlock from "../components/ContentBlock";

const htmlToReactParser = new Parser();

const Text = styled.div`
  padding: 0 30px;
`;

const IconTeaserContainer = ({ headline, items }) => (
  <section>
    <ContentBlock flatter>
      <Layout.ContentContainer>
        {htmlToReactParser.parse(headline.html)}
        <IconTeaser.Container>
          {items.map(({ title1, text, image }) => (
            <IconTeaser key={uniqueId("iconTeaser")}>
              <img alt={title1.text} src={image.url} />
              <h4>{title1.text}</h4>
              <Text>{htmlToReactParser.parse(text.html)}</Text>
            </IconTeaser>
          ))}
        </IconTeaser.Container>
      </Layout.ContentContainer>
    </ContentBlock>
  </section>
);

export const sliceId = "iconteasercontainer";
export default IconTeaserContainer;
