/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { Parser } from 'html-to-react'
import Lottie from 'react-lottie-player'
import H1 from '../components/H1'
import bp from '../utils/media'
import animationData1 from '../lottie/DynamicPatter-ViewPort1-Tile3.json'
import animationData2 from '../lottie/QuarterLines-SVG.json'
import animationData3 from '../lottie/DynamicPatter-ViewPort1-Tile1.json'
import animationData4 from '../lottie/DynamicPatter-ViewPort1-Tile2.json'
import Layout from '../components/Layout'
import PatternTile from '../components/PatternTile'
import Eagle from '../assets/img/eagle.svg'

const htmlToReactParser = new Parser()

const styling = css`
  display: flex;
  flex-direction: column;
  padding: 90px 0;
  background: #ededed;
  overflow: hidden;
  max-width: 100%;

  ${bp.medium} {
    padding: 20px 0;
  }

  .content-container {
    width: 100%;
  }

  h1 {
    width: 667px;
    max-width: 100%;
    margin-bottom: -70px;
    z-index: 2;
    position: relative;

    ${bp.large} {
      margin-bottom: -10px;
    }
  }

  video {
    width: 700px;
    height: auto;
    max-width: 100%;
    display: block;
    z-index: 1;
    position: relative;
  }

  .video-stage-video-container {
    position: relative;
    padding-left: 200px;
    max-width: 900px;

    ${bp.large} {
      padding-left: 100px;
    }
    ${bp.medium} {
      padding-left: 0;
    }
  }

  svg {
    path {
      stroke: #ffaa6e;
      fill: #ffaa6e;
    }
  }

  .video-stage-x-pattern {
    position: absolute;
    width: 200px;
    height: 200px;
    right: 0;
    bottom: -100px;
    z-index: 2;

    ${bp.medium} {
      width: 100px;
      height: 100px;
      bottom: -50px;
    }
  }

  .video-stage-dot-pattern {
    position: absolute;
    width: 200px;
    height: 200px;
    left: 0;
    top: 115px;

    ${bp.medium} {
      width: 100px;
      height: 100px;
      top: 50px;
      left: -50px;
    }
  }

  .video-stage-quarter-pattern {
    position: absolute;
    width: 100px;
    height: 100px;
    left: 100px;
    top: 15px;

    ${bp.medium} {
      width: 50px;
      height: 50px;
      top: 0;
      left: -50px;
    }
  }

  .video-stage-cross-pattern {
    position: absolute;
    width: 200px;
    height: 200px;
    right: -250px;
    top: 115px;
    z-index: 2;
  }

  .video-stage-image-left {
    position: absolute;
    left: 100px;
    top: 215px;
    z-index: 2;

    ${bp.medium} {
      width: 100px;
      height: auto;
      left: -70px;
      top: 70px;
    }
  }

  .video-stage-image-top-right {
    position: absolute;
    right: -100px;
    top: -150px;
    z-index: 0;

    ${bp.medium} {
      width: 150px;
      height: auto;
      right: -120px;
      top: -100px;
    }
  }

  .video-stage-eagle {
    position: absolute;
    right: -100px;
    bottom: -50px;

    ${bp.medium} {
      width: 40px;
      height: auto;
      right: -50px;
      bottom: -30px;
    }
  }
`

const Text = styled.div`
  width: 770px;
  max-width: 100%;
  margin-top: 9px;
  padding-right: 10px;
  padding-left: 210px;
  position: relative;
  z-index: 2;
  margin-top: 25px;

  ${bp.large} {
    padding-left: 110px;
  }
  ${bp.medium} {
    padding-left: 0;
  }

  strong {
    font-family: 'neue-haas-grotesk-display';
    font-size: 28px;
    margin-top: 20px;
    display: block;
  }
`

const VideoStage = ({ h1, h2, text, video, image_left, image_top_right }) => (
  <section css={styling}>
    <Layout.ContentContainer>
      <H1>
        {h1.text}
        <H1.SubHeadline>{h2.text}</H1.SubHeadline>
      </H1>
      <div className="video-stage-video-container">
        <video loop muted autoPlay playsInline src={video.url} />
        <Eagle className="video-stage-eagle" />
        <PatternTile loop className="video-stage-x-pattern" animationData={animationData1} />
        <PatternTile className="video-stage-quarter-pattern" animationData={animationData2} />
        <PatternTile loop className="video-stage-dot-pattern" animationData={animationData3} />
        <PatternTile loop className="video-stage-cross-pattern" animationData={animationData4} />
        <img
          className="video-stage-image-left"
          src={image_left.url}
          width={image_left.dimensions.width / 2}
          height={image_left.dimensions.height / 2}
          alt=""
        />
        <img
          className="video-stage-image-top-right"
          src={image_top_right.url}
          width={image_top_right.dimensions.width / 2}
          height={image_top_right.dimensions.height / 2}
          alt=""
        />
      </div>
      <Text>{htmlToReactParser.parse(text.html)}</Text>
    </Layout.ContentContainer>
  </section>
)

export const sliceId = 'videostage'
export default VideoStage
