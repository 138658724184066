/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import React from 'react'
import styled from '@emotion/styled'
import { Parser } from 'html-to-react'
import { uniqueId } from 'lodash'
import ContentIntro from '../components/ContentIntro'
import Button from '../components/Button'
import ContentBlock from '../components/ContentBlock'
import Layout from '../components/Layout'
import bp from '../utils/media'
import PatternTile from '../components/PatternTile'
import animationData1 from '../lottie/Xpattern-Medium-SVG.json'
import animationData2 from '../lottie/QuarterInvert-SVG.json'
import animation1Data3 from '../lottie/DynamicPatter-ViewPort2-Tile1.json'
import animation1Data4 from '../lottie/DynamicPatter-ViewPort2-Tile2.json'
import animation1Data5 from '../lottie/DynamicPatter-ViewPort2-Tile3.json'
import animationData3 from '../lottie/DotsSmall.json'
import animationData4 from '../lottie/QuarterLines-SVG.json'
import animationData5 from '../lottie/LinesBold-SVG.json'
import animationData6 from '../lottie/LinesThin-SVG.json'
import animationData7 from '../lottie/Quarter-SVG.json'
import animation2Data1 from '../lottie/DynamicPatter-ViewPort3-Tile1.json'
import animation2Data2 from '../lottie/DynamicPatter-ViewPort3-Tile2.json'
import animation2Data3 from '../lottie/DynamicPatter-ViewPort3-Tile3.json'

const htmlToReactParser = new Parser()

const styling = css`
  position: relative;

  &:before {
    content: '';
    width: 100%;
    background: red;
    height: 70%;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
  }
`

const GalleryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  align-items: flex-start;

  img {
    margin-bottom: 20px;

    &:last-child:first-of-type {
      ${bp.medium} {
      }
    }

    ${props =>
      css`
        margin-left: ${props.imageOffset ? '300px' : '0'};
        ${bp.medium} {
          margin-left: 0;
          width: 100% !important;
        }
      `}
  }

  .image-top-right {
    position: absolute;

    ${bp.medium} {
      width: 100px !important;
      height: auto;
    }
    ${props =>
      css`
        top: ${props.imageOffset ? '-210px' : '-100px'};
        left: ${props.imageOffset ? '500px' : '789px'};

        ${bp.medium} {
          top: ${props.imageOffset ? 'auto' : '-100px'};
          right: ${props.imageOffset ? '-50px' : '-75px'};
          bottom: ${props.imageOffset ? '70%' : 'auto'};
          left: auto;
        }
      `}
  }

  .image-bottom-left {
    position: absolute;
    left: -58px;
    bottom: -130px;
    z-index: -1;

    ${bp.medium} {
      width: 200px !important;
      height: auto;
      bottom: -70px;
    }
  }
`

const animation1Dots = css`
  position: absolute;
  width: 100px;
  height: 100px;
  top: -150px;
  right: -50px;

  ${bp.medium} {
    width: 50px;
    height: 50px;
    top: -75px;
    right: -25px;
  }
`
const animation2Dots = css`
  position: absolute;
  width: 100px;
  height: 100px;
  top: 0px;
  right: -100px;

  ${bp.medium} {
    width: 50px;
    height: 50px;
    right: -50px;
  }
`
const animation1Cross = css`
  position: absolute;
  width: 200px;
  height: 200px;
  top: -50px;
  right: -150px;

  ${bp.medium} {
    width: 100px;
    height: 100px;
    right: -75px;
    top: -25px;
  }
`
const animation2Cross = css`
  position: absolute;
  width: 200px;
  height: 200px;
  top: -100px;
  right: 0;

  ${bp.medium} {
    width: 100px;
    height: 100px;
    top: -50px;
  }
`
const animation1Quarter = css`
  position: absolute;
  width: 100px;
  height: 100px;
  top: -100px;
  right: 50px;

  ${bp.medium} {
    width: 50px;
    height: 50px;
    top: -50px;
    right: 25px;
  }
`
const animation2Quarter = css`
  position: absolute;
  width: 100px;
  height: 100px;
  top: 0;
  right: -200px;

  ${bp.medium} {
    width: 50px;
    height: 50px;
    right: -100px;
  }
`
const animation1Rainbow = css`
  position: absolute;
  width: 100px;
  height: 100px;
  top: -100px;
  left: 989px;
  transform: rotate(90deg);

  ${bp.medium} {
    width: 50px;
    height: 50px;
  }
`
const animation2Rainbow = css`
  position: absolute;
  width: 100px;
  height: 100px;
  top: 100px;
  right: -100px;

  ${bp.medium} {
    width: 50px;
    height: 50px;
    right: -50px;
    top: 50px;
  }
`
const animation2RainbowLarge = css`
  position: absolute;
  width: 200px;
  height: 200px;
  top: 100px;
  left: -200px;

  ${bp.medium} {
    width: 100px;
    height: 100px;
  }
`
const animation1Lines = css`
  position: absolute;
  width: 100px;
  height: 100px;
  top: -50px;
  left: -10px;

  ${bp.medium} {
    width: 50px;
    height: 50px;
    left: -60px;
  }
`
const animation2Lines = css`
  position: absolute;
  width: 100px;
  height: 100px;
  top: -100px;
  right: -100px;
  transform: rotate(90deg);

  ${bp.medium} {
    width: 50px;
    height: 50px;
    top: -50px;
    right: -50px;
  }
`
const animation2QuarterInvert = css`
  position: absolute;
  width: 100px;
  height: 100px;
  bottom: -100px;
  left: 210px;
  transform: rotate(270deg);

  ${bp.medium} {
    width: 50px;
    height: 50px;
  }
`

const ContentBlockGallery = ({
  title1,
  subtitle,
  text,
  items,
  button_text,
  text_background,
  text_color,
  link,
  graphicsdisabled,
  noimageoffset,
  animation,
  image_bottom_left,
  image_top_right,
}) => (
  <ContentBlock css={styling} background={graphicsdisabled}>
    <Layout.ContentContainer>
      <ContentIntro graphicsdisabled={graphicsdisabled} background={text_background.url} color={text_color}>
        <ContentIntro.SubTitle>{subtitle.text}</ContentIntro.SubTitle>
        <ContentIntro.Title>{title1.text}</ContentIntro.Title>
        <ContentIntro.Text>{text.text}</ContentIntro.Text>
        {animation === 'animation1' && <PatternTile loop css={animation1Dots} animationData={animation1Data3} />}
        {animation === 'animation1' && (
          <PatternTile offset={0.2} css={animation1Cross} animationData={animationData1} />
        )}
        {animation === 'animation1' && (
          <PatternTile offset={0.3} css={animation1Quarter} animationData={animationData2} />
        )}
        {animation === 'animation2' && <PatternTile loop css={animation2Cross} animationData={animation2Data1} />}
        {animation === 'animation2' && <PatternTile offset={0.5} css={animation2Dots} animationData={animationData3} />}
        {animation === 'animation2' && (
          <PatternTile offset={0.5} css={animation2Lines} animationData={animation2Data3} loop />
        )}
        {animation === 'animation2' && (
          <PatternTile offset={0.8} css={animation2Rainbow} animationData={animation2Data2} loop />
        )}
        {animation === 'animation2' && (
          <PatternTile offset={0.9} css={animation2Quarter} animationData={animationData5} />
        )}
        {animation === 'animation2' && (
          <PatternTile offset={1.5} css={animation2RainbowLarge} animationData={animationData4} />
        )}
        {animation === 'animation2' && (
          <PatternTile offset={1} css={animation2QuarterInvert} animationData={animationData7} />
        )}
      </ContentIntro>
      <GalleryContainer imageOffset={animation === 'animation2'}>
        {items.map(({ image }) => (
          <>
            {!image.mobile && (
              <img
                key={uniqueId()}
                src={image.url}
                alt=""
                style={{ width: `${(image.dimensions.width / 1180) * 50}%`, height: 'auto' }}
              />
            )}
            {image.mobile && (
              <picture>
                <source
                  srcSet={image.mobile.url}
                  width={image.mobile.dimensions.width}
                  height={image.mobile.dimensions.height}
                  alt=""
                  media="(max-width: 450px)"
                />
                <img
                  key={uniqueId()}
                  src={image.url}
                  alt=""
                  style={{ width: `${(image.dimensions.width / 1180) * 50}%`, height: 'auto' }}
                />
              </picture>
            )}
          </>
        ))}
        {image_bottom_left.url && (
          <img
            className="image-bottom-left"
            width={image_bottom_left.dimensions.width / 2}
            height={image_bottom_left.dimensions.height / 2}
            src={image_bottom_left.url}
            alt=""
          />
        )}
        {image_top_right.url && (
          <img
            className="image-top-right"
            width={image_top_right.dimensions.width / 2}
            height={image_top_right.dimensions.height / 2}
            src={image_top_right.url}
            alt=""
          />
        )}
        {animation === 'animation1' && (
          <PatternTile loop offset={0.5} css={animation1Rainbow} animationData={animation1Data5} />
        )}
        {animation === 'animation1' && (
          <PatternTile loop offset={0.6} css={animation1Lines} animationData={animation1Data4} />
        )}
      </GalleryContainer>
      {button_text && (
        <ContentBlock.Footer>
          <Button noAnimation href={link && link.text}>
            {button_text.text}
          </Button>
        </ContentBlock.Footer>
      )}
    </Layout.ContentContainer>
  </ContentBlock>
)

export const sliceId = 'contentblockgallery'
export default ContentBlockGallery
