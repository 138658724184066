/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import styled from "@emotion/styled";
import { Parser } from "html-to-react";
import { uniqueId } from "lodash";
import Button from "../components/Button";
import Layout from "../components/Layout";
import ContentBlock from "../components/ContentBlock";
import bp from "../utils/media";

const htmlToReactParser = new Parser();

const styling = css`
  h2 {
    text-align: center;
    max-width: 780px;
    margin: 0 auto 56px;
  }
`;
const buttonContainer = css`
  text-align: center;
`;

const Contact = ({ headline, button, email, background }) => (
  <section css={styling}>
    <ContentBlock background={background}>
      <Layout.ContentContainer>
        <div class="inner-content">
          {htmlToReactParser.parse(headline.html)}
          <div css={buttonContainer}>
            <Button href={`mailto:${email}`} noAnimation>
              {button}
            </Button>
          </div>
        </div>
      </Layout.ContentContainer>
    </ContentBlock>
  </section>
);

export const sliceId = "contact";
export default Contact;
