/** @jsx jsx */
import styled from '@emotion/styled'
import { css, jsx } from '@emotion/core'
import { Field } from 'formik'
import bp from '../utils/media'

const styling = css`
  font-size: 16px;
  background: #d5d5d5;
  border: 0;
  font-weight: bold;
  padding: 40px 20px;
  outline: 0;
  flex-grow: 1;
  font-family: 'RationalTWText-Light';
  color: #2b2b2b;
  margin-right: 20px;
  border-radius: 0;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: #2b2b2b;
    -webkit-box-shadow: 0 0 0px 1000px #d5d5d5 inset;
    transition: background-color 5000s ease-in-out 0s;
    font-family: 'RationalTWText-Light';
  }

  ${bp.medium} {
    padding: 30px 20px;
  }
`

const Input = props => <Field css={styling} {...props} />

export default Input
