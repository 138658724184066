import React, { useState } from "react";
import { graphql } from "gatsby";
import * as Scroll from "react-scroll";
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from "react-scroll";
import { kebabCase } from "lodash";
import Layout from "../components/Layout";
import Slices from "../slices/_map";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Helmet from "react-helmet";
import Button from "../components/Button";

export default (props) => {
  const { data } = props;
  const content = data.prismicLandingpage.data;
  const lang = data.prismicLandingpage.lang;

  return (
    <Layout>
      <Helmet>
        <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png" />
        <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png" />
        <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png" />
        <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png" />
        <link rel="apple-touch-icon" sizes="114x114" href="/apple-icon-114x114.png" />
        <link rel="apple-touch-icon" sizes="120x120" href="/apple-icon-120x120.png" />
        <link rel="apple-touch-icon" sizes="144x144" href="/apple-icon-144x144.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/apple-icon-152x152.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png" />
        <link rel="icon" type="image/png" sizes="192x192" href="/android-icon-192x192.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/manifest.json" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff" />
        <title>culture-council.eu</title>
        <meta name="description" content="The future of Europe - in stories where it is visible today." />
      </Helmet>
      <Header />
      <Slices body={content.body}></Slices>
      <Footer>
        <a href={`${lang === "de-de" ? "/" : `/${lang.split("-")[0]}/`}impressum`}>Impressum</a>
        <strong>cc.eu copyright 2022</strong>
      </Footer>
    </Layout>
  );
};

export const pageQuery = graphql`
  query LandingPageQuery($id: String) {
    prismicLandingpage(id: { eq: $id }) {
      data {
        body {
          ... on PrismicLandingpageBodyRichtext {
            id
            primary {
              navigationtitle {
                text
              }
              richtext {
                html
              }
              title {
                html
              }
            }
            slice_type
          }
          ... on PrismicLandingpageBodyContentblockwithbackground {
            id
            primary {
              background {
                url
              }
              headline {
                html
              }
              subheadline {
                html
              }
              text {
                html
              }
            }
            slice_type
          }
          ... on PrismicLandingpageBodyContentteaser {
            id
            slice_type
            primary {
              headline {
                html
              }
            }
            items {
              image {
                url
              }
              text {
                html
              }
            }
          }
          ... on PrismicLandingpageBodyIconteasercontainer {
            id
            primary {
              headline {
                html
              }
            }
            items {
              image {
                url
              }
              text {
                html
              }
              title1 {
                text
              }
            }
            slice_type
          }
          ... on PrismicLandingpageBodyTextimagecontentnarrow {
            id
            primary {
              background {
                url
              }
              image {
                url
              }
              text {
                html
              }
            }
            slice_type
          }
          ... on PrismicLandingpageBodyContact {
            id
            slice_type
            primary {
              background {
                url
              }
              button
              email
              headline {
                html
              }
            }
          }
          ... on PrismicLandingpageBodyContentvideo {
            id
            primary {
              background {
                url
              }
              headline {
                html
              }
              video {
                url
              }
            }
            slice_type
          }
          ... on PrismicLandingpageBodySpotifyembed {
            id
            primary {
              embedsrc
              dark
              compact
            }
            slice_type
          }
        }
      }
      type
      lang
    }
  }
`;
