/** @jsx jsx */
import styled from "@emotion/styled";
import { css, jsx } from "@emotion/core";
import {
  secondaryHeadline,
  largeText,
  largeTextLight,
} from "../styles/typography";
import bp from "../utils/media";

const styling = css`
  max-width: 580px;
  padding-bottom: 240px;
  margin: 0 auto;

  ${bp.medium} {
    padding-bottom: 40px;
  }

  &:after {
    content: "";
    height: 2px;
    width: 51%;
    background: black;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  p {
    ${secondaryHeadline};
    margin-top: 0.5em;
    width: 100% !important;
  }

  img {
    max-width: 100%;
  }
`;

const TextImageContentNarrow = ({ children }) => (
  <div css={styling}>{children}</div>
);

export default TextImageContentNarrow;
