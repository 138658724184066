/** @jsx jsx */
import styled from '@emotion/styled'
import { css, jsx } from '@emotion/core'
import { secondaryHeadline, secondarySubHeadline } from '../styles/typography'
import bp from '../utils/media'

const baseStyling = css`
  position: relative;
  padding-bottom: 40px;

  h1 {
    ${secondaryHeadline}
    max-width: 600px;
    margin-bottom: 20px;
    position: relative;
    z-index: 2;

    ${bp.medium} {
      margin-bottom: 40px;
    }
  }
  h2 {
    ${secondarySubHeadline}
    color: #ff497a;
    max-width: 500px;
    margin-bottom: 0.25em;
  }

  .content-intro-text {
    max-width: 712px;

    ${bp.medium} {
      padding-left: 0;
    }
  }
`

const backgroundStyling = css`
  background-size: cover;
  max-width: 550px;
  margin-left: 90px;
  padding: 40px 50px 90px;
  margin-bottom: -60px;

  ${bp.medium} {
    margin-left: -10px;
    padding: 20px 20px 90px;
    max-width: 90%;
  }

  h1 {
    ${bp.medium} {
      margin-bottom: 15px;
    }
  }
`
// update

const ContentIntro = ({ children, graphicsdisabled, background, color }) => (
  <div
    css={css`
      ${baseStyling};
      ${graphicsdisabled ? '' : backgroundStyling};
      ${background ? `background-image: url(${background})` : ''};
      color: ${color};
    `}
  >
    {children}
  </div>
)

ContentIntro.SubTitle = ({ children }) => <h2>{children}</h2>
ContentIntro.Title = ({ children }) => <h1>{children}</h1>
ContentIntro.Text = ({ children }) => <div className="content-intro-text">{children}</div>

export default ContentIntro
