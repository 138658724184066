import React, { useState } from "react";
import { useInView } from "react-hook-inview";
import styled from "@emotion/styled";
import bp from "../utils/media";
import logo from "../assets/img/BildTextLogo.png";

const StyledContainer = styled.div`
  padding: 20px 0;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 222;

  .content-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    position: relative;

    &:after {
      content: "";
      height: 2px;
      width: 66%;
      background: black;
      position: absolute;
      bottom: -20px;
      left: 62px;

      ${bp.medium} {
        left: 17px;
        width: 90%;
      }
    }
  }

  .logo {
    ${bp.medium} {
      width: 90px;
      height: auto;
      image-rendering: -webkit-optimize-contrast;
    }
  }

  a {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    position: relative;
    z-index: 23;

    img {
      display: block;
    }
  }

  .lang-switcher {
    margin-left: auto;
    align-self: center;

    a,
    a:visited {
      color: inherit;
      text-decoration: none;
      margin-left: 15px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const Header = ({ children }) => (
  <StyledContainer>
    <div className="content-container">
      <a href="/">
        <img src={logo} className="logo" width={139} height={61} alt="Culture Council Europe" />
      </a>
      <nav class="lang-switcher">
        <a href="/">de</a>
        <a href="/en">en</a>
      </nav>
    </div>
  </StyledContainer>
);

export default Header;
