/** @jsx jsx */
import styled from "@emotion/styled";
import { css, jsx } from "@emotion/core";
import { secondaryHeadline, secondarySubHeadline } from "../styles/typography";
import bp from "../utils/media";

const containerStyling = css`
  display: flex;
  flex-wrap: wrap;
  margin-left: -120px;

  ${bp.medium} {
    margin-left: 0;
  }
`;

const textStyling = css`
  margin-top: 20px;

  p {
    width: 100% !important;
  }
`;

const styling = css`
  display: flex;
  flex-direction: column;
  max-width: 380px;
  margin-left: 120px;
  margin-bottom: 48px;

  img {
    max-width: 100%;
    height: auto;
  }

  ${bp.medium} {
    width: 100%;
    margin-left: 0;
  }
`;

const ContentTeaser = ({ children }) => <div css={styling}>{children}</div>;

ContentTeaser.Container = ({ children }) => (
  <div css={containerStyling}>{children}</div>
);
ContentTeaser.Text = ({ children }) => <div css={textStyling}>{children}</div>;

export default ContentTeaser;
