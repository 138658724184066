import React, { useState } from 'react'
import { useField } from 'formik'
import styled from '@emotion/styled'

const StyledCheckbox = styled.label`
  width: 50px;
  height: 50px;
  display: inline-block;
  background: #d5d5d5;
  border: 5px solid transparent;
  position: relative;
  margin-right: 15px;
  flex-shrink: 0;
  cursor: pointer;

  input {
    visibility: hidden;
  }

  .checkbox-checkmark {
    position: absolute;
    left: 7px;
    top: 5px;
    width: 25px;
    height: 14px;
    border-bottom: 5px solid #2b2b2b;
    transform: rotate(-45deg);
    transition: 0.3s cubic-bezier(0.83, 0, 0.17, 1);
    opacity: 0;

    &:before {
      content: '';
      width: 25px;
      height: 14px;
      display: block;
      border-left: 5px solid #1d1d1b;
      border-bottom: 5px solid #1d1d1b;
    }
  }

  input:checked + .checkbox-checkmark {
    height: 22px;
    opacity: 1;
  }
`

const StyledCheckboxContainer = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-bottom: 0;
  }
  a,
  a:visited {
    color: black;
  }
`

const Checkbox = ({ children, ...props }) => {
  const [field] = useField({ ...props, type: 'checkbox' })
  return (
    <StyledCheckboxContainer>
      <StyledCheckbox>
        <input type="checkbox" {...field} {...props} />
        <div className="checkbox-checkmark"></div>
      </StyledCheckbox>
      <div className="label-text">{children} </div>
    </StyledCheckboxContainer>
  )
}

export default Checkbox
