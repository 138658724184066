/** @jsx jsx */
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Formik, Form, ErrorMessage } from 'formik'
import { css, jsx } from '@emotion/core'
import axios from 'axios'
import Input from './Input'
import Button from './Button'
import Checkbox from './Checkbox'
import bp from '../utils/media'

const styling = css`
  margin-bottom: 40px;
  position: relative;
  ${bp.medium} {
    padding-bottom: 52px;
  }
`

const StyledFormRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  ${bp.medium} {
    display: block;

    input {
      width: 100%;
    }

    button {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }
`

const StyledErrors = styled.div`
  margin-top: -10px;
  margin-bottom: 20px;
  color: #ffaa6e;
`

const NewsletterForm = ({
  errorEmailRequired,
  errorEmailWrongFormat,
  errorTermsRequired,
  successMessage,
  termsLabel,
  doubleOptInRedirectUrl,
  sendinblueListId,
  sendinblueTemplateId,
  sendinblueAPIKEY,
}) => {
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)

  return (
    <Formik
      initialValues={{ email: '', OPT_IN: false }}
      validate={values => {
        const errors = {}
        if (!values.email) {
          errors.EMAIL = errorEmailRequired
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
          errors.EMAIL = errorEmailWrongFormat
        }
        if (!values.OPT_IN) {
          errors.OPT_IN = errorTermsRequired
        }
        return errors
      }}
      onSubmit={(values, { setSubmitting }) => {
        setLoading(true)
        const options = {
          method: 'POST',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'api-key': sendinblueAPIKEY,
          },
          data: JSON.stringify(
            {
              includeListIds: [sendinblueListId],
              redirectionUrl: doubleOptInRedirectUrl,
              templateId: 1,
              ...values,
            },
            null,
            2
          ),
          url: 'https://api.sendinblue.com/v3/contacts/doubleOptinConfirmation',
        }
        axios(options).then(res => {
          setLoading(false)
          if (res.status === 201 || res.status === 204) {
            setSuccess(true)
          }
        })
        setSubmitting(false)
      }}
    >
      {({ isSubmitting }) => (
        <Form css={styling}>
          {loading && <div>Sending...</div>}
          {!success && !loading && (
            <>
              <StyledFormRow>
                <Input type="text" name="email" placeholder="Email" />
                <Button type="submit" disabled={isSubmitting} noAnimation>
                  send
                </Button>
              </StyledFormRow>
              <StyledErrors>
                <ErrorMessage name="email" />
              </StyledErrors>
              <StyledFormRow>
                <Checkbox name="OPT_IN">
                  <span>I confirm that I've read and accept the&nbsp;</span>
                  <a href="/imprint">terms and conditions</a>.
                </Checkbox>
              </StyledFormRow>
              <StyledErrors>
                <ErrorMessage name="OPT_IN" />
              </StyledErrors>
            </>
          )}
          {!loading && success && <p>{successMessage}</p>}
        </Form>
      )}
    </Formik>
  )
}

export default NewsletterForm
