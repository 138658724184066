/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { Parser } from 'html-to-react'
import { StaticQuery, graphql } from 'gatsby'
import { uniqueId } from 'lodash'
import ContentIntro from '../components/ContentIntro'
import Button from '../components/Button'
import HighlightBox from '../components/HighlightBox'
import NewsletterForm from '../components/NewsletterForm'

const htmlToReactParser = new Parser()

const NewsletterSignUp = ({
  htmlid,
  title1,
  description,
  erroremailrequired,
  erroremailformat,
  errortermsrequired,
  doubleoptinredirecturl,
  sendinblueapikey,
  sendinbluelistid,
  sendinbluetemplateid,
  termslabel,
}) => (
  <section id={htmlid && htmlid.text}>
    <HighlightBox>
      <h2>{title1.text}</h2>
      <p>{description.text}</p>
      <NewsletterForm
        errorEmailRequired={erroremailrequired.text}
        errorEmailWrongFormat={erroremailformat.text}
        errorTermsRequired={errortermsrequired.text}
        successMessage="Thank you. Check your emails to confirm your subscription."
        termsLabel={htmlToReactParser.parse(termslabel.html)}
        doubleOptInRedirectUrl={doubleoptinredirecturl.url}
        sendinblueListId={parseInt(sendinbluelistid.text, 10)}
        sendinblueTemplateId={parseInt(sendinbluetemplateid.text, 10)}
        sendinblueAPIKEY={sendinblueapikey.text}
      />
      {/* <HighlightBox.Footer>
        <p>Or just contact us on</p>
        <Button href="#" noAnimation secondary>
          LinkedIn
        </Button>
      </HighlightBox.Footer> */}
    </HighlightBox>
  </section>
)

export const sliceId = 'newslettersignup'
export default NewsletterSignUp
