/** @jsx jsx */
import styled from "@emotion/styled";
import { css, jsx } from "@emotion/core";
import bp from "../utils/media";
import {
  primaryHeadline,
  secondaryHeadline,
  tertiaryHeadline,
} from "../styles/typography";

const baseStyling = css`
  position: relative;
  padding: 100px 0;

  ${bp.medium} {
    padding: 26px 0;
  }

  h1 {
    ${primaryHeadline};
    margin-bottom: 1em;
  }

  h2 {
    ${secondaryHeadline};
    margin-bottom: 0.75em;
  }

  h3 {
    ${tertiaryHeadline};
    margin-bottom: 32px;
  }

  h5 {
    ${primaryHeadline};
    margin-bottom: 20px;
    color: #4100ff;
  }

  h1 + h2 {
    margin-top: -1em;
  }

  p {
    width: 48%;

    ${bp.medium} {
      width: 100%;
    }
  }

  p + p {
    margin-top: 1em;
  }
  .content-container {
    padding-left: 162px;
    padding-right: 162px;

    ${bp.large} {
      padding-left: 62px;
      padding-right: 62px;
    }
    ${bp.medium} {
      padding: 0 17px;
    }
  }

  .inner-content {
    width: 100%;
  }
`;
const backgroundStyling = (url) => css`
  background: url(${url});
  background-size: contain;
  background-position: 50% 0;
  background-repeat: no-repeat;

  .content-container {
    display: flex;
    &:after {
      content: "";
      display: block;
      padding-bottom: 50.38%;
    }
  }
`;

const textStyle = css`
  padding: 100px 95px;
  width: 100%;
`;

const flatterStyle = css`
  padding-top: 0;
  padding-bottom: 0;
`;

const ContentBlock = ({ children, background, flatter }) => (
  <section
    css={css`
      ${baseStyling};
      ${background ? backgroundStyling(background.url) : ""}
      ${flatter ? flatterStyle : ""}
    `}
  >
    {children}
  </section>
);

export default ContentBlock;
