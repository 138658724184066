/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { Parser } from "html-to-react";
import { uniqueId } from "lodash";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import TextImageContentNarrow from "../components/TextImageContentNarrow";
import Button from "../components/Button";
import Layout from "../components/Layout";
import bp from "../utils/media";
import ContentBlock from "../components/ContentBlock";

const htmlToReactParser = new Parser();

const styling = css`
  display: flex;
  padding: 125px 0 225px;

  ${bp.medium} {
  }
`;

const Text = styled.div`
  padding: 0 30px;
`;

const TextImageContentNarrowSlice = ({ image, text, background }) => (
  <section>
    <ContentBlock background={background} flatter>
      <Layout.ContentContainer>
        <TextImageContentNarrow>
          <img src={image.url} />
          {htmlToReactParser.parse(text.html)}
        </TextImageContentNarrow>
      </Layout.ContentContainer>
    </ContentBlock>
  </section>
);

export const sliceId = "textimagecontentnarrow";
export default TextImageContentNarrowSlice;
