/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import { kebabCase } from "lodash";
import { Element } from "react-scroll";
import VideoStage, { sliceId as VideoStageId } from "./VideoStage";
import IconTeaserContainer, { sliceId as IconTeaserContainerId } from "./IconTeaserContainer";
import RichText, { sliceId as RichTextId } from "./RichText";
import ContentBlockGallery, { sliceId as ContentBlockGalleryId } from "./ContentBlockGallery";
import ContentBlockWithBackground, { sliceId as ContentBlockWithBackgroundId } from "./ContentBlockWithBackground";
import NewsletterSignUp, { sliceId as NewsletterSignUpId } from "./NewsletterSignUp";
import TextImageContentNarrowSlice, { sliceId as TextImageContentNarrowSliceId } from "./TextImageContentNarrow";
import ContentTeaserContainer, { sliceId as ContentTeaserId } from "./ContentTeaserContainer";
import ContentVideo, { sliceId as ContentVideoId } from "./ContentVideo";
import SpotifyEmbed, { sliceId as SpotifyEmbedId } from "./SpotifyEmbed";
import Contact, { sliceId as ContactId } from "./Contact";

const Slices = ({ body }) => (
  <>
    {body.map(({ slice_type, id, primary, items }) => {
      console.log(slice_type, primary);
      return (
        <Element>
          {slice_type === VideoStageId && <VideoStage {...primary} />}
          {slice_type === IconTeaserContainerId && <IconTeaserContainer {...primary} items={items} />}
          {slice_type === ContentTeaserId && <ContentTeaserContainer {...primary} items={items} />}
          {slice_type === ContentBlockWithBackgroundId && <ContentBlockWithBackground {...primary} />}
          {slice_type === ContentVideoId && <ContentVideo {...primary} />}
          {slice_type === SpotifyEmbedId && <SpotifyEmbed {...primary} />}
          {slice_type === ContactId && <Contact {...primary} />}
          {slice_type === TextImageContentNarrowSliceId && <TextImageContentNarrowSlice {...primary} />}
          {slice_type === RichTextId && <RichText {...primary} />}
        </Element>
      );
    })}
  </>
);

export const sliceId = "videostage";
export default Slices;
