/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import styled from "@emotion/styled";
import { Parser } from "html-to-react";
import Layout from "../components/Layout";
import ContentBlock from "../components/ContentBlock";
import bp from "../utils/media";

const htmlToReactParser = new Parser();

const styling = css`
  .player {
    margin: 0 auto;
    max-width: 720px;
  }
`;

const SpotifyEmbed = ({ embedsrc, dark, compact }) => (
  <section css={styling}>
    <ContentBlock>
      <Layout.ContentContainer>
        <div className="player">
          <iframe
            src={`${embedsrc}${dark ? "&theme=0" : ""}`}
            width="100%"
            height={compact ? 152 : 232}
            frameBorder="0"
            allowfullscreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          ></iframe>
        </div>
      </Layout.ContentContainer>
    </ContentBlock>
  </section>
);

export const sliceId = "spotifyembed";
export default SpotifyEmbed;
