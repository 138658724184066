/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import styled from "@emotion/styled";
import { Parser } from "html-to-react";
import { uniqueId } from "lodash";
import Button from "../components/Button";
import Layout from "../components/Layout";
import ContentBlock from "../components/ContentBlock";
import bp from "../utils/media";

const htmlToReactParser = new Parser();

const styling = css``;

const ContentBlockWithBackground = ({
  headline,
  subheadline,
  text,
  background,
}) => (
  <section>
    <ContentBlock background={background}>
      <Layout.ContentContainer>
        <div class="inner-content">
          {htmlToReactParser.parse(headline.html)}
          {subheadline && htmlToReactParser.parse(subheadline.html)}
          {htmlToReactParser.parse(text.html)}
        </div>
      </Layout.ContentContainer>
    </ContentBlock>
  </section>
);

export const sliceId = "contentblockwithbackground";
export default ContentBlockWithBackground;
