import React, { useState } from "react";
import { useInView } from "react-hook-inview";
import styled from "@emotion/styled";
import bp from "../utils/media";
import logo from "../assets/img/BildTextLogo.png";

const StyledContainer = styled.div`
  padding: 30px 0;

  svg {
    margin-right: 40px;
  }

  .content-container {
    display: flex;
    position: relative;
  }

  .items {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-grow: 1;
  }
  .logo {
    ${bp.medium} {
      width: 90px;
      height: auto;
      image-rendering: -webkit-optimize-contrast;
    }
  }

  a,
  a:visited {
    color: black;
  }

  ${bp.large} {
    .content-container {
      flex-direction: column;
    }

    strong {
      position: absolute;
      right: 62px;
      top: 5px;
    }

    .items {
      flex-wrap: wrap;
      padding-top: 30px;
      line-height: 1.6;
      width: 317px;
      margin: 0 auto;
    }

    a {
      flex-grow: 1;
      text-align: center;
    }
  }
  ${bp.medium} {
    strong {
      right: 30px;
    }
  }
`;

const Footer = ({ children }) => (
  <StyledContainer>
    <div className="content-container">
      <img
        src={logo}
        alt="Retail Xperience Innovations"
        width={139}
        height={61}
        className="logo"
      />
      <div className="items">{children}</div>
    </div>
  </StyledContainer>
);

export default Footer;
