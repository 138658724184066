/** @jsx jsx */
import styled from '@emotion/styled'
import React, { useState } from 'react'
import { useInView } from 'react-hook-inview'
import { css, jsx } from '@emotion/core'
import Lottie from 'react-lottie-player'
import animationData from '../lottie/DotsMedium.json'

const styling = css`
  overflow: hidden;
`

const PatternTile = ({ children, offset = 0, loop = false, ...props }) => {
  const [play, setPlay] = useState(true)
  const [segments, setSegments] = useState([0, 33])

  const [ref] = useInView(
    {
      onEnter: () => {
        setTimeout(() => {
          setSegments([0, 33])
        }, 1000 * offset)
      },
      onLeave: () => {
        setSegments([120, 150])
      },
    },
    [play]
  )
  return (
    <div ref={ref} css={styling}>
      <Lottie loop={loop} segments={!loop && segments} play={play} {...props} />
    </div>
  )
}

export default PatternTile
