/** @jsx jsx */
import styled from '@emotion/styled'
import { css, jsx } from '@emotion/core'
import bp from '../utils/media'
import { primaryHeadline } from '../styles/typography'

const Headline = styled.h1`
  ${primaryHeadline}
`
const styling = css`
  .subheadline {
    font-family: 'TimesNow-Light';
    margin-top: 20px;
    display: block;
    font-size: 0.622em;
    font-weight: normal;
    transform: scaleX(1.3);
    transform-origin: 0 0;
    letter-spacing: -2px;
    max-width: 90%;

    ${bp.large} {
      max-width: 80%;
    }
    ${bp.medium} {
      max-width: 70%;
      margin-top: 0;
      letter-spacing: -1px;
    }
  }
`
const H1 = ({ children }) => <Headline css={styling}>{children}</Headline>

H1.SubHeadline = ({ children }) => <span className="subheadline">{children}</span>

export default H1
