import bp from "../utils/media";

const primaryHeadline = `
  font-size: 60px;
  line-height: 1.07;
  letter-spacing: -1px;
  font-family: "Rational-Euroculture-Re-TWText-SemiBold";

  ${bp.medium} {
    font-size: 50px;
  }
`;

const secondaryHeadline = `
  font-size: 42px;
  letter-spacing: -1px;
  line-height: 1.23;
  font-family: "Rational-Euroculture-Re-TWText-SemiBold";

  ${bp.medium} {
    font-size: 38px;
    line-height: 1.26;
  }
`;

const tertiaryHeadline = `
  font-size: 32px;
  letter-spacing: -1px;
  line-height: 1.3125;
  text-align: center;
  font-family: "Rational-Euroculture-Re-TWText-SemiBold";
`;

const largeText = `
  font-size: 24px;
  letter-spacing: -1px;
  line-height: 1.25;
  font-family: "Rational-Euroculture-Re-TWText-SemiBold";
`;

export { primaryHeadline, secondaryHeadline, tertiaryHeadline, largeText };
