/** @jsx jsx */
import styled from '@emotion/styled'
import { css, jsx } from '@emotion/core'
import { secondaryHeadline } from '../styles/typography'
import bp from '../utils/media'
import Layout from './Layout'

const StyledBox = styled.div`
  padding: 60px 0;
  background: #ededed;

  ${bp.medium} {
  }

  h2 {
    ${secondaryHeadline}
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 1em;
  }

  .HighlightBox__container {
    max-width: 810px;
    margin: 0 auto;
  }
`

const StyledBoxFooter = styled.div`
  padding-top: 40px;
  border-top: 2px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const HighlightBox = ({ children, linkTo }) => (
  <StyledBox>
    <Layout.ContentContainer>
      <div className="HighlightBox__container">{children}</div>
    </Layout.ContentContainer>
  </StyledBox>
)

HighlightBox.Footer = ({ children }) => <StyledBoxFooter>{children}</StyledBoxFooter>

export default HighlightBox
