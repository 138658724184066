/** @jsx jsx */
import styled from "@emotion/styled";
import { css, jsx } from "@emotion/core";
import { largeText } from "../styles/typography";
import bp from "../utils/media";

const containerStyling = css`
  display: flex;
  align-items: flex-start;
  position: relative;
  padding-bottom: 40px;

  &:after {
    content: "";
    height: 2px;
    width: 51%;
    background: black;
    position: absolute;
    bottom: 0;
    right: 20%;
  }

  ${bp.medium} {
    flex-direction: column;
    padding-bottom: 0;
  }
`;
const styling = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1 1 0;
  text-align: center;

  ${bp.medium} {
    margin-bottom: 56px;
    width: 100%;
    flex: 0 0 100%;
  }

  img {
    margin-bottom: 20px;
    width: 100px;
    height: auto;
  }

  h4 {
    ${largeText}
    margin-bottom: 16px;
  }

  p {
    width: 100% !important;
  }

  ${bp.small} {
  }
`;

const IconTeaser = ({ children }) => <li css={styling}>{children}</li>;

IconTeaser.Container = ({ children }) => (
  <ul css={containerStyling}>{children}</ul>
);

export default IconTeaser;
