/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import styled from "@emotion/styled";
import { Parser } from "html-to-react";
import Layout from "../components/Layout";
import ContentBlock from "../components/ContentBlock";
import bp from "../utils/media";

const htmlToReactParser = new Parser();

const styling = css`
  video {
    width: 100%;
  }
`;
const headlineStyling = css`
  margin-top: 150px;
  margin-bottom: 80px;
  max-width: 560px;
  color: #4100ff;

  ${bp.medium} {
    margin-top: 30px;
    margin-bottom: 40px;
  }
`;

const ContentVideo = ({ headline, video, background }) => (
  <section css={styling}>
    <ContentBlock background={background}>
      <Layout.ContentContainer>
        <div class="inner-content">
          <div css={headlineStyling}>
            {htmlToReactParser.parse(headline.html)}
          </div>
          <video src={video.url} loop muted autoPlay playsInline></video>
        </div>
      </Layout.ContentContainer>
    </ContentBlock>
  </section>
);

export const sliceId = "contentvideo";
export default ContentVideo;
